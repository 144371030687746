<template>
 <div>
  <div class="footer">
      <div class="container">
        <div class="logofooter">
            <img src="https://www.credix.com.mx/plantillav2/images/logo-foot.png" alt="credix-logo-footer">
            <div class="redes-sociales">
              
              <a href="https://mx.linkedin.com/company/credixmx" target="_blank">
                <svg style="width:30px;height:30px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M19 3A2 2 0 0 1 21 5V19A2 2 0 0 1 19 21H5A2 2 0 0 1 3 19V5A2 2 0 0 1 5 3H19M18.5 18.5V13.2A3.26 3.26 0 0 0 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17A1.4 1.4 0 0 1 15.71 13.57V18.5H18.5M6.88 8.56A1.68 1.68 0 0 0 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19A1.69 1.69 0 0 0 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56M8.27 18.5V10.13H5.5V18.5H8.27Z" />
                </svg>
              </a>
              <a href="https://www.credix.com.mx/" target="_blank">
                <svg style="width:30px;height:30px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />
                </svg>
              </a>
              <a href="https://es-la.facebook.com/Credix.mx/" target="_blank">
                <svg style="width:30px;height:30px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" />
                </svg>
              </a>
            </div>
        </div>
        <div class="row">
          <div class="col-md-5">
            <div class="datos-footer">
              <div class="ubicaciones">
                <p style="font-weight:600">CREDIX GS, S.A. DE C.V., SOFOM, E.N.R.</p>
                <p>Periférico Paseo de la República No. 2650, P4 Int. 5 Col. Prados del Campestre, CP. 58297 , Morelia, Mich.</p>
                <p><a href="mailto:atencion@credix.com.mx">
                  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z" />
                  </svg> 
                  <span>
atencion@credix.com.mx
                  </span>
                  </a> </p>
                <p><a href="tel:800 467 2836">
                  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M19.1,8.7C20.9,10.5 20.9,13.3 19.1,15.2L20.1,16.2C22.6,13.9 22.6,10.1 20.1,7.7L19.1,8.7M18,9.8L17,10.8C17.5,11.5 17.5,12.4 17,13.1L18,14.1C19.2,12.9 19.2,11.1 18,9.8M14,1H4A2,2 0 0,0 2,3V21A2,2 0 0,0 4,23H14A2,2 0 0,0 16,21V3A2,2 0 0,0 14,1M14,20H4V4H14V20Z" />
                    </svg>
                    <span>
                       800 467 2836
                    </span>
                  </a> </p> 
                <p><a href="tel:4432321000">
                  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M19.1,8.7C20.9,10.5 20.9,13.3 19.1,15.2L20.1,16.2C22.6,13.9 22.6,10.1 20.1,7.7L19.1,8.7M18,9.8L17,10.8C17.5,11.5 17.5,12.4 17,13.1L18,14.1C19.2,12.9 19.2,11.1 18,9.8M14,1H4A2,2 0 0,0 2,3V21A2,2 0 0,0 4,23H14A2,2 0 0,0 16,21V3A2,2 0 0,0 14,1M14,20H4V4H14V20Z" />
                    </svg>
                    <span>
                        443 232 1000 ext 120
                    </span>
                  </a> </p> 
              </div>
            </div>
          </div>
            <div class="col-md-2">
                <div class="contenido-footer" >
                  <div class="item-footer"><h4>CREDIX</h4></div> 
                  <div class="item-footer"> <router-link to="/nosotros">Nosotros</router-link>  </div>
                  <!-- <div class="item-footer"><router-link to="/brokers-alta">Brokers</router-link> </div> -->
                  <a class="item-footer" href="https://credix.com.mx/altaBrokers.php">Brokers</a>
                  <div class="item-footer"><router-link to="/registro-proveedor">Proveedores</router-link> </div>
                  <a class="item-footer" href="./relInvesionistas.php">Relación con inversionistas</a>
              </div>
            </div>
            <div class="col-md-2">
              <div class="contenido-footer">
                  <div class="item-footer"><h4>Servicios</h4></div> 
                  <div class="item-footer"> <router-link to="/solicitar-credito">Crédito</router-link>  </div>
                  <!-- <div class="item-footer"><router-link to="/solicitar-factoraje-financiero">Factoraje</router-link> </div> -->
              </div>
            </div>
            <div class="col-md-3">
              <div class="contenido-footer">
                  <div class="item-footer"><h4>Contacto</h4></div> 
                  <div class="item-footer"><router-link to="/une">UNE</router-link> </div>
                  <!--div class="item-footer"><router-link to="/normativa">Normativa</router-link> </div-->
                  <div class="item-footer"><router-link to="/aviso-de-privacidad">Aviso de privacidad</router-link> </div>
                  <div class="item-footer"><router-link to="/preguntas-frecuentes">Preguntas frecuentes</router-link> </div>
              </div>
            </div>
            
        </div>
      </div>
  </div>
  <div class="footer2">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <p>CREDIX GS, S.A. de C.V., SOFOM, E.N.R., en su constitución y operación no requiere de autorización de la Secretaría de Hacienda y Crédito Público y está sujeta a la supervisión de la Comisión Nacional Bancaria y de Valores únicamente en materia de prevención y detección de Operaciones con Recursos de Procedencia Ilícita y Financiamiento al Terrorismo.</p>
          <p>© Copyright CREDIX México 2022. Todos los Derechos Reservados. Prohibida la reproducción total o parcial del contenido de este sitio.</p>
        </div>
        <div class="col-md-4">
          <div class="reconocimientos">
            <img @click="modalBuro=true" src="@/assets/icons/Buro.svg" alt="Buro de credito Credix">
            <img  @click="href('https://www.gob.mx/cnbv')" src="@/assets/icons/CNBV.svg" alt="CNBV Credix">
            <img @click="href('https://www.banxico.org.mx/CAT/')" src="@/assets/icons/banxico.svg" alt="Banxico Credix">
            <img @click="href('https://www.gob.mx/condusef')" src="@/assets/icons/Condusef.svg" alt="Condusef Credix">
            <img @click="href('https://asofom.mx/directorio-de-socios/')" src="@/assets/icons/ASOFOM.svg" alt="Asofom Credix">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="socialContact" id="social-share">
     
      <div class="itemCn TelContac" tooltip="telefono" > 
        <a href="tel:800 467 2836" target="_blank">
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M19.1,8.7C20.9,10.5 20.9,13.3 19.1,15.2L20.1,16.2C22.6,13.9 22.6,10.1 20.1,7.7L19.1,8.7M18,9.8L17,10.8C17.5,11.5 17.5,12.4 17,13.1L18,14.1C19.2,12.9 19.2,11.1 18,9.8M14,1H4A2,2 0 0,0 2,3V21A2,2 0 0,0 4,23H14A2,2 0 0,0 16,21V3A2,2 0 0,0 14,1M14,20H4V4H14V20Z" />
          </svg>
        </a>
      </div>
      <div class="itemCn whatsContac"  tooltip="whatsapp" >
        <a href="https://wa.me/+5215535107320" target="_blank">
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z" />
          </svg>
        </a>
      </div>
      <div class="itemCn fbContact" tooltip="facebook">
        <a href="https://www.facebook.com/credix.mx/" target="_blank">
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M12,2C6.36,2 2,6.13 2,11.7C2,14.61 3.19,17.14 5.14,18.87C5.3,19 5.4,19.22 5.41,19.44L5.46,21.22C5.5,21.79 6.07,22.16 6.59,21.93L8.57,21.06C8.74,21 8.93,20.97 9.1,21C10,21.27 11,21.4 12,21.4C17.64,21.4 22,17.27 22,11.7C22,6.13 17.64,2 12,2M18,9.46L15.07,14.13C14.6,14.86 13.6,15.05 12.9,14.5L10.56,12.77C10.35,12.61 10.05,12.61 9.84,12.77L6.68,15.17C6.26,15.5 5.71,15 6,14.54L8.93,9.87C9.4,9.14 10.4,8.95 11.1,9.47L13.44,11.23C13.66,11.39 13.95,11.39 14.16,11.23L17.32,8.83C17.74,8.5 18.29,9 18,9.46Z" />
          </svg>
        </a>
      </div>
       <div class="itemCn" tooltip="share">
        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M20 15.5C18.75 15.5 17.55 15.3 16.43 14.93C16.08 14.82 15.69 14.9 15.41 15.17L13.21 17.37C10.38 15.93 8.06 13.62 6.62 10.79L8.82 8.58C9.1 8.31 9.18 7.92 9.07 7.57C8.7 6.45 8.5 5.25 8.5 4C8.5 3.45 8.05 3 7.5 3H4C3.45 3 3 3.45 3 4C3 13.39 10.61 21 20 21C20.55 21 21 20.55 21 20V16.5C21 15.95 20.55 15.5 20 15.5M12 3V13L15 10H21V3H12Z" />
        </svg>
      </div>
  </div>
  <modal v-if="modalBuro" @close="modalBuro = false">
  <h3 slot="header">Buró de Entidades Financieras</h3>
  <div slot="body">
    <h3>¿Qué es el Buró de Entidades Financieras?</h3>
    <p>Es una herramienta de consulta y difusión con la que podrás conocer los productos que ofrecen las entidades financieras, sus comisiones y tasas, las reclamaciones de los usuarios, las prácticas no sanas en que incurren, las sanciones administrativas que les han impuesto, las cláusulas abusivas de sus contratos y otra información que resulte relevante para informarte sobre su desempeño. <br>
    Con el Buró de Entidades Financieras, se logrará saber quién es quién en bancos, seguros, sociedades financieras de objeto múltiple, cajas de ahorro, afores, entre otras entidades. Con ello, podrás comparar y evaluar a las entidades financieras, sus productos y servicios y tendrás mayores elementos para elegir lo que más te convenga. <br>
    Esta información te será útil para elegir un producto financiero y también para conocer y usar mejor los que ya tienes. <br>
    El Buró de Entidades Financieras, es una herramienta que puede contribuir al crecimiento económico del país, al promover la competencia entre las instituciones financieras; que impulsará la transparencia al revelar información a los usuarios sobre el desempeño de éstas y los productos que ofrecen y que va a facilitar un manejo responsable de los productos y servicios financieros al conocer a detalle sus características. <br>
    Lo anterior, podrá derivar en un mayor bienestar social, porque al conjuntar en un solo espacio tan diversa información del sistema financiero, el usuario tendrá más elementos para optimizar su presupuesto, para mejorar sus finanzas personales, para utilizar correctamente los créditos que fortalecerán su economía y obtener los seguros que la protejan, entre otros aspectos.
    <br>
</p>
  <h3>Información CREDIX GS</h3>
  <p>El Buró de Entidades Financieras contiene información de CREDIX GS, S.A. de C.V., SOFOM, E.N.R. sobre nuestro desempeño frente a los Usuarios, por la prestación de productos y servicios. <br>
  Te invitamos a consultarlo en la página <a href="http://www.buro.gob.mx">http://www.buro.gob.mx</a>  o en este apartado.</p>
  <img src="@/assets/buro-modal.jpeg" alt="Buro de credito credix" style="width:100%">
  <h3>Información del sector</h3>
  <p>Para conocer la información de todo el sector, podrás acceder al sitio del Buró de Entidades Financieras que se encuentra en la siguiente liga: <a href="http://www.buro.gob.mx">http://www.buro.gob.mx</a> 
  La información del Buró de Entidades Financieras es una herramienta para mejorar tu futuro. <br>
  ¡Úsala a tu favor!
</p>
  </div>
</modal>
  </div>


  
</template>

<script>
import modal from '@/components/Modal.vue'
export default {
  components:{
    modal
  },
  methods:{
    href(link){
      document.location.href = link
    },
  },
  data(){
    return{
      modalBuro:false
    }
  }
}
</script>

<style lang="css">

 .footer {
  background-color: #171f2a;
  padding: 60px 0;
  color: #fff;
}
.footer .logofooter {
  display: flex;
  justify-content: space-between;
}
.footer .logofooter img {
  width: 200px;
}
.footer .logofooter .redes-sociales {
  display: flex;
}
.footer .logofooter .redes-sociales a {
  color: #fff;
  margin: 0 5px;
}
.footer .datos-footer .ubicaciones {
  margin-top: 2rem;
}
.footer  a{
  text-decoration: none;
  color:white
}
.footer .container {
  width: 1170px;
}
.footer .contenido-footer {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}
.footer .contenido-footer .item-footer {
  margin: 5px 0;
}
.footer .contenido-footer .item-footer h4 {
  letter-spacing: 1.8;
  font-weight: lighter;
}
.footer .contenido-footer .item-footer a {
  color: #fff;
  font-size: 0.9rem;
  text-decoration: none;
  letter-spacing: 0.5px;
}
.footer2 {
  background-color: #0e141d;
  padding: 3rem 0;
  color: #fff;
  font-size: 12px;
}
.footer2 .reconocimientos {
  display: flex;
  justify-content: flex-end;
}
.footer2 .reconocimientos img {
  height: 35px;
  margin: 0 2px;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .footer .container {
    width: 100%;
  }
  .footer .logofooter {
    align-items: center;
  }
}

.socialContact{
  bottom: 5%;
  position: fixed;
  margin: 1em;
  left: 0;
}
.itemCn{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  color: white;
  margin: 20px auto 0;
  box-shadow: 0px 5px 11px -2px rgba(0, 0, 0, 0.18), 0px 4px 12px -7px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  -webkit-transition: all .1s ease-out;
  transition: all .1s ease-out;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.itemCn a{
  color: #006699;
}
.itemCn >  svg {
  font-size: 38px;
  line-height: 60px;
  color:#006699;
  transition: all .2s ease-in-out;
  transition-delay: 2s;
}
.itemCn:active,
.itemCn:focus,
.itemCn:hover {
  box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28);
}
.itemCn:not(:last-child) {
  width: 60px;
  height: 60px;
  margin: 20px auto 0;
  opacity: 0;
}
.itemCn:not(:last-child) > svg {
  font-size: 25px;
  line-height: 60px;
  transition: all .3s ease-in-out;
}
.socialContact:hover .itemCn:not(:last-child) {
  opacity: 1;
  width: 60px;
  height: 60px;
  margin: 15px auto 0;
}
.itemCn:nth-last-child(1) {
  -webkit-transition-delay: 25ms;
  transition-delay: 25ms;
}
.itemCn:not(:last-child):nth-last-child(2) {
  -webkit-transition-delay: 20ms;
  transition-delay: 20ms;
}
.itemCn:not(:last-child):nth-last-child(3) {
  -webkit-transition-delay: 40ms;
  transition-delay: 40ms;
}
.itemCn:not(:last-child):nth-last-child(4) {
  -webkit-transition-delay: 60ms;
  transition-delay: 60ms;
}
.itemCn:not(:last-child):nth-last-child(5) {
  -webkit-transition-delay: 80ms;
  transition-delay: 80ms;
}
.itemCn:not(:last-child):nth-last-child(6) {
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms;
}
[tooltip]:hover:before,
[tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}
 
</style>